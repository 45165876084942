<template>
  <div>
    <app-toolbar :crumbs="crumbs" />

    <person-form v-model="model" @cancel="onCancel" @save="onSave" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import personApi from '@/services/api/person'
import personForm from './form'

export default {
  components: {
    appToolbar,
    personForm
  },
  data() {
    return {
      model: {}
    }
  },
  computed: {
    ...mapGetters('messages', ['errors']),
    id() {
      return this.$route.params.personId
    },
    crumbs() {
      const crumbs = [{ text: this.$t('people'), to: { name: 'people-index' } }]

      if (this.model) {
        crumbs.push({
          text: this.model.shortId,
          disabled: false,
          to: { name: 'person-detail', params: { personId: this.model.id } }
        })
      }

      crumbs.push({ text: this.$t('edit'), disabled: true })

      return crumbs
    }
  },
  async mounted() {
    this.model = await personApi.fetch(this.id)
  },
  methods: {
    onCancel() {
      this.$router.go(-1)
    },
    async onSave(model) {
      await personApi.put(model.id, model)
      this.$router.push({
        name: 'person-detail',
        params: { personId: model.id }
      })
    }
  }
}
</script>

<style></style>
